import { handleGet, handlePost /* handlePut */ } from './api';
class API {
    constructor() {
        this.GATO_API_URL = '';
        this.GET = handleGet('');
        this.POST = handlePost('');
    }
    configure(api_url) {
        this.GATO_API_URL = api_url;
        this.GET = handleGet(this.GATO_API_URL);
        this.POST = handlePost(this.GATO_API_URL);
    }
    async registerWithEmail(bodyData, extraHeaders) {
        return await this.POST('/api/user/register')(bodyData, extraHeaders);
    }
    async sendEmailToResetPassword(bodyData, extraHeaders) {
        return await this.POST('/api/user/reset')(bodyData, extraHeaders);
    }
    login(service) {
        return this.POST(`/api/user/${service}`);
    }
    async fbLogin(bodyData, extraHeaders) {
        return await this.login('facebook')(bodyData, extraHeaders);
    }
    async googleLogin(bodyData, extraHeaders) {
        return await this.login('google')(bodyData, extraHeaders);
    }
    async emailLogin(bodyData, extraHeaders) {
        return await this.login('login')(bodyData, extraHeaders);
    }
    async logout() {
        return await this.GET(`/api/user/logout`);
    }
    async refreshToken() {
        return await this.GET('/api/user/refreshToken');
    }
    async sendNewPassword({ password, token }) {
        return await this.POST(`/api/user/reset/${token}`)({ password });
    }
    async checkAllowsNews(token) {
        return await this.GET('/api/auth/newsletter/check', token).then(r => r.data.isLabs);
    }
    async sendAllowsNews(allowsNews, token) {
        return await this.POST('/api/auth/newsletter/set')({ isLabs: allowsNews }, { Authorization: token });
    }
}
export default new API();
