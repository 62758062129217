import React, { Suspense, useEffect } from 'react'

import {
  Switch,
  Route,
  useLocation,
  useHistory,
  Redirect,
} from 'react-router-dom'

import { Loading } from '@elgatoylacaja/components'

import { useLoader } from '@elgatoylacaja/hooks'
import { gatoAPI } from '@elgatoylacaja/utils'
import { loginRedirect } from 'utils'

const Login = React.lazy(() =>
  import(/* webpackChunkName: "Login" */ 'views/Login')
)
const Signup = React.lazy(() =>
  import(/* webpackChunkName: "Signup" */ 'views/Signup')
)
const NewPassword = React.lazy(() =>
  import(/* webpackChunkName: "NewPassword" */ 'views/NewPassword')
)
const ResetPassword = React.lazy(() =>
  import(/* webpackChunkName: "ResetPassword" */ 'views/ResetPassword')
)

const FacebookRedirect = React.lazy(() =>
  import(/* webpackChunkName: "FacebookRedirect" */ 'views/FacebookRedirect')
)

const GoogleRedirect = React.lazy(() =>
  import(/* webpackChunkName: "GoogleRedirect" */ 'views/GoogleRedirect')
)

const VALID_PATHS = [
  '/login',
  '/facebook-redirect',
  '/google-redirect',
  '/signup',
  '/reset',
  '/new-password',
]

const LazySwitchRoutes = () => {
  const { startLoading, stopLoading } = useLoader()
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    async function checkRefreshToken() {
      startLoading()
      try {
        const { data: result } = await gatoAPI.refreshToken()
        if (result.accessToken !== '') {
          await loginRedirect(result.accessToken)
        } else {
          if (!VALID_PATHS.includes(location.pathname)) {
            history.push('/login', { search: location.search })
          }
          stopLoading()
        }
      } catch (e) {
        console.error(e)
        stopLoading()
      }
    }

    const search = location.search
    const params = new URLSearchParams(search)
    const redirectUrl = params.get('redirectURL')
    if (redirectUrl !== null) {
      sessionStorage.setItem(
        'redirectUrl',
        redirectUrl.replace('__slash__', '#')
      )
    }

    checkRefreshToken()
  }, [location.search, location.pathname])

  return (
    <>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Redirect exact from="/auth-check" to="/login" />
        <Suspense fallback={<Loading loading fixed />}>
          <Route path={'/login'} component={Login} />
          <Route path={'/signup'} component={Signup} />
          <Route path={'/reset'} component={ResetPassword} />
          <Route path={'/new-password'} component={NewPassword} />

          <Route path={'/facebook-redirect'} component={FacebookRedirect} />
          <Route path={'/google-redirect'} component={GoogleRedirect} />
        </Suspense>
      </Switch>
    </>
  )
}

export default LazySwitchRoutes
