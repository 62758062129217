import * as React from 'react';
const FloatingText = React.createContext(undefined);
export function FloatingTextProvider(props) {
    const [shouldShowFloatingText, setShouldShowFloatingText] = React.useState(false);
    const [pathnames, setPathnames] = React.useState([]);
    const value = React.useMemo(() => ({
        shouldShowFloatingText,
        setShouldShowFloatingText,
        pathnames,
        setPathnames,
    }), [shouldShowFloatingText, pathnames]);
    return React.createElement(FloatingText.Provider, Object.assign({ value: value }, props));
}
export default function useFLoatingText(pathnames, location) {
    const context = React.useContext(FloatingText);
    if (!context) {
        throw new Error(`useFLoatingText must be used within a FloatingTextProvider`);
    }
    const { shouldShowFloatingText, setShouldShowFloatingText, pathnames: contextPathnames, setPathnames, } = context;
    React.useEffect(() => {
        setPathnames(() => pathnames);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const showFloatingText = React.useCallback(() => setShouldShowFloatingText(_ => true), [setShouldShowFloatingText]);
    const hideFloatingText = React.useCallback(() => setShouldShowFloatingText(_ => false), [setShouldShowFloatingText]);
    React.useEffect(() => {
        if (contextPathnames.find(path => path.includes(location)) !== undefined) {
            showFloatingText();
        }
        else {
            hideFloatingText();
        }
    }, [contextPathnames, location, hideFloatingText, showFloatingText]);
    return {
        shouldShowFloatingText,
    };
}
