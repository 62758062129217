import { handleGet, handlePost } from './api';
class API {
    constructor() {
        this.PERFIL_API_URL = '';
        this.GET = handleGet('');
        this.POST = handlePost('');
    }
    configure(api_url) {
        this.PERFIL_API_URL = api_url;
        this.GET = handleGet(this.PERFIL_API_URL);
        this.POST = handlePost(this.PERFIL_API_URL);
    }
    async getExperimentsToDo(token) {
        return await this.GET(`/perfil/experimentosPendientes`, token);
    }
    async getExperimentsDone(token) {
        return await this.GET(`/perfil/experimentosHechos`, token);
    }
}
export default new API();
