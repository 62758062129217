import React from 'react';
import { MenuItem, FormControl, FormLabel, Paper, makeStyles, Divider, Typography, Button, } from '@material-ui/core';
import useAppStyles from './useAppStyles';
import TextField from './TextField';
import CustomErrorMessage from './CustomFormikErrorMessage';
import { useCombobox, useMultipleSelection } from 'downshift';
import { matchSorter } from 'match-sorter';
import { ErrorMessage } from 'formik';
const useStyles = makeStyles({
    input: {
        '@media only screen and (max-width : 800px)': {
            fontSize: 15,
        },
        fontSize: 18,
        fontFamily: 'Gotham',
        fontWeight: 400,
        color: 'var(--secondary)',
        textAlign: 'left',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        '&::placeholder': {
            color: 'rgb(162, 162, 162)',
            opacity: '1',
        },
    },
    cssUnderline: {
        '&:after': {
            borderBottomColor: 'var(--primary)',
        },
    },
});
const itemToString = (item) => (item ? item.label : '');
const Suggestion = (suggestionProps) => {
    const { suggestion, isHighlighted, isSelected, itemProps, custom = false, } = suggestionProps;
    const appStyles = useAppStyles();
    return (React.createElement(MenuItem, Object.assign({}, itemProps, { selected: isHighlighted, component: "div", style: {
            fontWeight: isSelected ? 500 : 400,
            fontFamily: 'Gotham',
        } }),
        custom ? (React.createElement(Typography, { variant: "caption", className: appStyles.textLight, style: {
                display: 'inline-flex',
                marginRight: '2px',
                width: 'fit-content',
            } }, "Agregar:")) : null,
        ' ',
        suggestion.label));
};
const lowerJoin = (text) => text
    .toLowerCase()
    .split(' ')
    .join('');
const SuggestionTextField = (props) => {
    const appStyles = useAppStyles();
    const classes = useStyles();
    const { values, initialValue, allowOthers = false, onSelectedItemsChange, } = props;
    const [items, setItems] = React.useState(values);
    const [inputItems, setInputItems] = React.useState(items);
    const onCreateItem = newItem => setItems(prev => [...prev, newItem]);
    const isCreating = React.useMemo(() => {
        return inputItems.length < 4;
    }, [inputItems]);
    React.useEffect(() => {
        setInputItems(items);
    }, [items]);
    const optionFilterFunc = React.useCallback((items, inputValue) => {
        return matchSorter(items, inputValue, {
            keys: [
                { maxRanking: matchSorter.rankings.STARTS_WITH, key: 'label' },
                'extra',
            ],
        }).slice(0, props.limit);
    }, [props.limit]);
    const { getSelectedItemProps, getDropdownProps, addSelectedItem, removeSelectedItem, selectedItems, } = useMultipleSelection({
        onSelectedItemsChange: ({ selectedItems }) => {
            onSelectedItemsChange(selectedItems);
        },
    });
    const selectedItemValues = selectedItems.map(item => item.value);
    const { isOpen, getLabelProps, getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps, openMenu, selectItem, inputValue, closeMenu, } = useCombobox({
        items: inputItems,
        onInputValueChange: ({ inputValue }) => {
            const filteredItems = optionFilterFunc(values, inputValue || '');
            if (filteredItems.length < 4 && allowOthers) {
                setInputItems([
                    ...filteredItems,
                    { label: `${inputValue}`, value: inputValue, id: 999 },
                ]);
            }
            else {
                setInputItems(filteredItems);
            }
        },
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;
            switch (type) {
                case useCombobox.stateChangeTypes.InputBlur:
                    return {
                        ...changes,
                        highlightedIndex: state.highlightedIndex,
                        inputValue: '',
                    };
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        highlightedIndex: state.highlightedIndex,
                        isOpen: true,
                        inputValue: '',
                    };
                default:
                    return changes;
            }
        },
        onStateChange: ({ type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (selectedItem) {
                        const inOriginalValues = values
                            .map(v => v.value)
                            .includes(selectedItem.value);
                        if (selectedItemValues.includes(selectedItem.value)) {
                            removeSelectedItem(selectedItem);
                            if (!inOriginalValues) {
                                setInputItems(prev => prev.filter(iv => iv.value !== selectedItem.value));
                            }
                        }
                        else {
                            if (onCreateItem && isCreating && !inOriginalValues) {
                                onCreateItem(selectedItem);
                            }
                            addSelectedItem(selectedItem);
                        }
                        selectItem(null);
                    }
                    closeMenu();
                    break;
                default:
                    break;
            }
        },
        itemToString,
    });
    const { onBlur, onFocus, autoComplete, ...inputProps } = getInputProps(getDropdownProps({
        onClick: isOpen ? () => { } : openMenu,
        onFocus: isOpen ? () => { } : openMenu,
    }));
    return (React.createElement(FormControl, Object.assign({ fullWidth: true, className: appStyles.formControl }, getComboboxProps()),
        React.createElement(FormLabel, Object.assign({}, getLabelProps(), { className: appStyles.formLabel }), props.label),
        React.createElement("div", { style: {
                display: 'flex',
                flexWrap: 'wrap',
                marginTop: '16px',
            } }, selectedItems.map((selectedItem, index) => (React.createElement(Button, Object.assign({ key: `selected-item-${index}` }, getSelectedItemProps({ selectedItem, index }), { className: "pill", style: {
                width: 'fit-content',
                padding: '5px 12px',
                display: 'inline-flex',
                bordeRadius: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '25px',
                marginRight: '8px',
                textTransform: 'none',
                border: '1px solid var(--primary)',
                background: 'rgba(var(--primary-rgb), 0.2)',
            }, onClick: e => {
                e.stopPropagation();
                removeSelectedItem(selectedItem);
            }, "aria-label": "Remove small badge" }),
            selectedItem.label,
            '  ',
            "\u2715")))),
        React.createElement(TextField, { InputProps: {
                onBlur,
                onFocus,
                classes: {
                    input: classes.input,
                    underline: classes.cssUnderline,
                },
            }, fullWidth: true, inputProps: inputProps, placeholder: props.placeholder, autoComplete: "off", onKeyDown: e => {
                if (e.key === 'Enter') {
                    e.preventDefault();
                }
            } }),
        React.createElement("div", Object.assign({}, getMenuProps()), isOpen ? (React.createElement(Paper, { square: true }, inputItems.map((suggestion, index, list) => {
            const inputCreating = list.length - 1 === index && isCreating;
            return (React.createElement(React.Fragment, { key: `${suggestion.value}-${suggestion.id}` },
                inputCreating ? React.createElement(Divider, null) : null,
                React.createElement(Suggestion, { suggestion: suggestion, custom: inputCreating, itemProps: getItemProps({
                        item: suggestion,
                        index,
                    }), isSelected: selectedItemValues.includes(suggestion.value), isHighlighted: highlightedIndex === index })));
        }))) : null),
        React.createElement(ErrorMessage, { name: props.id, component: CustomErrorMessage })));
};
export default SuggestionTextField;
