import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
const Loading = ({ color = 'var(--primary)', height = '100vh', loading = false, fixed = false, style, }) => {
    const fixedStyle = fixed
        ? {
            position: 'fixed',
            left: '0',
            top: '0',
            backgroundColor: '#fff',
            zIndex: 1500,
        }
        : {};
    return loading ? (React.createElement(Grid, { container: true, justify: "center", alignItems: "center", style: {
            height,
            ...fixedStyle,
            ...style,
        } },
        React.createElement(Grid, { item: true, xs: 12, sm: 12, md: 8, lg: 6, style: { textAlign: 'center' } },
            React.createElement(CircularProgress, { style: { color }, size: 100, thickness: 4 })))) : null;
};
export default Loading;
