"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var GatoLogo = React.createContext(undefined);
function GatoLogoProvider(props) {
    var _a = React.useState(false), shouldShowGatoLogo = _a[0], setShouldShowGatoLogo = _a[1];
    var _b = React.useState([]), pathnames = _b[0], setPathnames = _b[1];
    var value = React.useMemo(function () { return ({
        shouldShowGatoLogo: shouldShowGatoLogo,
        setShouldShowGatoLogo: setShouldShowGatoLogo,
        pathnames: pathnames,
        setPathnames: setPathnames
    }); }, [shouldShowGatoLogo, pathnames]);
    return React.createElement(GatoLogo.Provider, __assign({ value: value }, props));
}
exports.GatoLogoProvider = GatoLogoProvider;
function useGatoLogo(pathnames, location) {
    var context = React.useContext(GatoLogo);
    if (!context) {
        throw new Error("useGatoLogo must be used within a GatoLogoProvider");
    }
    var shouldShowGatoLogo = context.shouldShowGatoLogo, setShouldShowGatoLogo = context.setShouldShowGatoLogo, contextPathnames = context.pathnames, setPathnames = context.setPathnames;
    React.useEffect(function () {
        setPathnames(function () { return pathnames; });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var showGatoLogo = React.useCallback(function () { return setShouldShowGatoLogo(function (_) { return true; }); }, [setShouldShowGatoLogo]);
    var hideGatoLogo = React.useCallback(function () { return setShouldShowGatoLogo(function (_) { return false; }); }, [setShouldShowGatoLogo]);
    React.useEffect(function () {
        if (contextPathnames.find(function (path) { return path.includes(location); }) !== undefined) {
            showGatoLogo();
        }
        else {
            hideGatoLogo();
        }
    }, [contextPathnames, location, hideGatoLogo, showGatoLogo]);
    return {
        shouldShowGatoLogo: shouldShowGatoLogo
    };
}
exports["default"] = useGatoLogo;
