import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link as RouterLink } from 'react-router-dom';
const useStyles = (color) => makeStyles({
    link: {
        fontSize: '16px',
        lineHeight: ' 24px',
        fontFamily: 'Gotham',
        textDecoration: 'none',
        borderBottom: `1px solid ${color}`,
        color: color,
        paddingBottom: '2px',
        transition: 'all 0.2s',
        '&:hover': {
            opacity: 0.7,
            borderBottom: `3px solid ${color}`,
        },
    },
});
const Link = (props) => {
    const { children, color, to, style } = props;
    const classes = useStyles(color)();
    return (React.createElement(RouterLink, { to: to, className: classes.link, style: {
            letterSpacing: '0px',
            ...style,
        } }, children));
};
export default Link;
