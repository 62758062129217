import analyticsUtils from './analyticsUtils';
const share = (baseUrl, extra) => {
    window.open(`${baseUrl}${extra}`, '_blank');
};
const twitterShare = (text, url) => {
    share(`http://twitter.com/share`, `?text=${encodeURI(text)}&url=${encodeURI(url).replace('#', '%23')}`);
};
const whatsappShare = (text, url) => {
    share('https://api.whatsapp.com/send', `?text=${encodeURI(text)} - ${encodeURI(url).replace('#', '%23')}`);
};
const facebookShare = (url) => {
    share(`https://www.facebook.com/sharer.php`, `?u=${encodeURI(url).replace('#', '%23')}`);
};
const shareExperiment = (EXPERIMENT_SHARE_TEXT, EXPERIMENT_URL) => (service) => () => {
    switch (service) {
        case 'whatsapp':
            analyticsUtils.CLICK_SHARE(service);
            whatsappShare(EXPERIMENT_SHARE_TEXT, EXPERIMENT_URL + '?share=whatsapp');
            break;
        case 'twitter':
            analyticsUtils.CLICK_SHARE(service);
            twitterShare(EXPERIMENT_SHARE_TEXT, EXPERIMENT_URL + '?share=twitter');
            break;
        case 'facebook':
            analyticsUtils.CLICK_SHARE(service);
            facebookShare(EXPERIMENT_URL + '?share=facebook');
            break;
        case 'clipboard':
            analyticsUtils.CLICK_SHARE(service);
            break;
    }
};
export default {
    shareExperiment,
};
