const handleResponse = async (response) => {
    if (response.ok) {
        if (response.status !== 204) {
            return (await response.json());
        }
        else {
            return {};
        }
    }
    else {
        const error = {
            status: response.status,
            error: await response.json(),
        };
        throw error;
    }
};
const handleReqWithBody = (method) => (api_url) => (path, token) => async (bodyData, extraHeaders) => await fetch(`${api_url}${path}`, {
    method,
    credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token !== undefined
            ? { Authorization: `Bearer ${token}`, 'X-AUTH-TOKEN': token }
            : {}),
        ...extraHeaders,
    },
    body: JSON.stringify(bodyData),
}).then(r => handleResponse(r));
export const handleGet = (api_url) => async (path, token) => await fetch(`${api_url}${path}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(token !== undefined
            ? { Authorization: `Bearer ${token}`, 'X-AUTH-TOKEN': token }
            : {}),
    },
}).then(r => handleResponse(r));
export const handlePost = handleReqWithBody('POST');
export const handlePut = handleReqWithBody('PUT');
