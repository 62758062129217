import React from 'react';
import { RadioGroup as MUIRadioGroup, makeStyles, FormControl, FormLabel, Radio, FormControlLabel, } from '@material-ui/core';
import useAppStyles from './useAppStyles';
import CustomErrorMessage from './CustomFormikErrorMessage';
import { ErrorMessage } from 'formik';
import TooltipLabel from './TooltipLabel';
const useStyles = makeStyles({
    radioLabel: {
        fontFamily: 'Gotham',
        fontWeight: 400,
        fontSize: 18,
        color: 'var(--secondary)',
        cursor: 'pointer',
        display: 'flex',
        '@media only screen and (max-width : 500px)': {
            fontSize: 15,
        },
    },
    labelBoxText: {
        color: '#CCCCCC',
        paddingLeft: '9px',
        paddingRight: '9px',
        '@media only screen and (max-width : 500px)': {
            paddingLeft: '6px',
            paddingRight: '6px',
            fontSize: 11,
        },
    },
    labelBox: {
        textAlign: 'left',
        width: '100%',
        maxWidth: '150px',
        '@media only screen and (max-width : 500px)': {
            maxWidth: 'none',
        },
        margin: '0 5px',
        paddingBottom: '9px',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '10px',
        alignItems: 'flex-start',
        border: '2px solid #C8CED4',
        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.2s',
        '& span': {
            color: '#C8CED4 !important',
            '@media only screen and (max-width : 500px)': {
                fontWeight: 500,
            },
        },
        '&:hover': {
            borderColor: 'var(--primary)',
            boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.1)',
            '& span': {
                color: '#C8CED4',
            },
        },
        '&:first-child': {
            marginLeft: '0px',
        },
    },
    selectedLabelBox: {
        borderColor: 'var(--primary)',
        boxShadow: '0px 2px 4px 1px rgba(204, 204, 204, 0.5)',
        '& span': {
            color: 'var(--primary) !important',
        },
    },
    top: {
        maxWidth: '40px',
        textAlign: 'center',
    },
    bottom: {
        maxWidth: '40px',
        textAlign: 'center',
    },
    start: {
        textAlign: 'right',
    },
    end: {
        textAlign: 'left',
    },
    radioMargin: {
        marginRight: '10px',
    },
    radio: {
        color: '#C8CED4',
        '@media only screen and (max-width : 500px)': {
            padding: '6px',
        },
        '&:hover': {
            // backgroundColor: 'rgba(104, 187, 232, 0.15)',
            backgroundColor: 'transparent',
        },
    },
    radioChecked: {
        color: 'var(--primary) !important',
        '&:hover': {
            backgroundColor: 'rgba(104, 187, 232, 0.15) !important',
        },
    },
    radioGroup: {
        display: 'flex',
        marginTop: '18px',
    },
    horizontal: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
    },
    vertical: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
});
const RadioGroup = (props) => {
    const classes = useStyles();
    const appStyles = useAppStyles();
    const { values, style, labelPlacement = 'bottom', direction = 'horizontal', justify = 'space-between', tooltipIcon, labelBoxStyle = {}, ...rest } = props;
    return React.useMemo(() => (React.createElement(FormControl, { className: appStyles.formControl, fullWidth: true },
        React.createElement(FormLabel, { className: appStyles.formLabel }, props.label),
        React.createElement(MUIRadioGroup, Object.assign({ id: props.id, name: props.id, className: `${classes.radioGroup} ${classes[direction]}`, value: props.value, onChange: props.onChange, style: { justifyContent: justify, ...style } }, rest), values.map(({ id, label, value, tooltip }, i) => {
            const CustomTooltip = tooltip;
            return (React.createElement(FormControlLabel, { key: id, classes: {
                    label: `${classes.radioLabel} ${direction === 'horizontal' ? classes.labelBoxText : ''}`,
                }, className: direction === 'horizontal'
                    ? `${classes.labelBox} ${props.value === id.toString()
                        ? classes.selectedLabelBox
                        : ''}`
                    : classes[labelPlacement], style: labelBoxStyle, value: id.toString(), control: React.createElement(Radio, { disableFocusRipple: true, disableTouchRipple: true, disableRipple: true, classes: {
                        root: `${classes.radio} ${direction === 'vertical' ? classes.radioMargin : ''}`,
                        checked: classes.radioChecked,
                    } }), label: React.createElement(React.Fragment, null,
                    label,
                    tooltip !== undefined &&
                        (typeof tooltip === 'string' ? (React.createElement(TooltipLabel, { placement: "top", text: tooltip, Icon: tooltipIcon })) : (CustomTooltip))), labelPlacement: labelPlacement }));
        })),
        React.createElement(ErrorMessage, { name: props.id, component: CustomErrorMessage }))), [props.value]);
};
export default RadioGroup;
