import React from 'react';
import { Switch, makeStyles } from '@material-ui/core';
import LOGO_IMG from './logo';
const useSwitchStyles = makeStyles((theme) => ({
    root: {
        width: 40,
        height: 30,
        padding: 0,
    },
    switchBase: {
        marginTop: '2px',
        marginBottom: '2px',
        padding: '0 2px',
        '&$checked': {
            transform: 'translateX(20px)',
            color: 'white',
            '& + $track': {
                backgroundColor: 'var(--secondary)',
                opacity: 1,
            },
        },
        '&$focusVisible $thumb': {
            color: 'red',
            background: 'red',
        },
    },
    thumb: {
        width: 16,
        height: 16,
    },
    track: {
        height: '18px',
        display: 'flex',
        borderRadius: 40 / 2,
        backgroundColor: 'var(--secondary)',
        opacity: 1,
        border: '1px solid var(--secondary)',
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}));
const LanguageSwitch = (props) => {
    const classes = useSwitchStyles();
    return (React.createElement(Switch, Object.assign({ focusVisibleClassName: classes.focusVisible, disableRipple: true, classes: {
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
        } }, props)));
};
const useStyles = makeStyles((theme) => ({
    logo: {
        height: 60,
        display: 'block',
        margin: '0px auto',
        paddingTop: '24px',
    },
    rightAction: {
        display: 'flex',
        flexDirection: 'row-reverse',
        alignContent: 'center',
        width: '40%',
        fontWeight: 600,
        paddingTop: '24px',
        margin: 'auto 0',
        fontSize: '15px',
    },
}));
const GatoHeader = (props) => {
    const { languageControl, logoutControl } = props;
    const classes = useStyles();
    const isEnglish = languageControl && languageControl.current === 'en';
    return (React.createElement("div", { style: {
            display: 'flex',
            padding: '0 24px',
        } },
        React.createElement("div", { style: { width: '40%' } }),
        React.createElement("img", { src: LOGO_IMG, alt: "Logo gato", className: classes.logo }),
        React.createElement("div", { className: classes.rightAction }, languageControl ? (React.createElement(React.Fragment, null,
            React.createElement("span", { style: {
                    marginLeft: '5px',
                    lineHeight: '19px',
                    cursor: 'pointer',
                    color: isEnglish ? 'var(--secondary)' : 'var(--gray-dark)',
                }, onClick: languageControl.setEnglish }, "EN"),
            React.createElement(LanguageSwitch, { checked: isEnglish, onChange: languageControl.onChange }),
            React.createElement("span", { style: {
                    marginRight: '5px',
                    lineHeight: '19px',
                    cursor: 'pointer',
                    color: isEnglish ? 'var(--gray-dark)' : 'var(--secondary)',
                }, onClick: languageControl.setSpanish }, "ES"))) : logoutControl ? (React.createElement(React.Fragment, null,
            React.createElement("span", { style: {
                    marginLeft: '5px',
                    lineHeight: '19px',
                    cursor: 'pointer',
                    color: 'var(--secondary)',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '17px',
                }, onClick: logoutControl.onClick },
                React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
                    React.createElement("mask", { id: "mask0", "mask-type": "alpha", maskUnits: "userSpaceOnUse", x: "4", y: "6", width: "16", height: "12" },
                        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M9.61404 6H5.49708C4.67057 6 4 6.67188 4 7.5V16.5C4 17.3281 4.67057 18 5.49708 18H9.61404C9.81988 18 9.9883 17.8313 9.9883 17.625V17.375C9.9883 17.1687 9.81988 17 9.61404 17H5.49708C5.22261 17 4.99805 16.775 4.99805 16.5V7.5C4.99805 7.225 5.22261 7 5.49708 7H9.61404C9.81988 7 9.9883 6.83125 9.9883 6.625V6.375C9.9883 6.16875 9.81988 6 9.61404 6ZM13.9774 6.83125L14.1988 6.60938C14.3454 6.4625 14.5825 6.4625 14.729 6.60938L19.8628 11.7344C20.0094 11.8812 20.0094 12.1187 19.8628 12.2656L14.7322 17.3906C14.5856 17.5375 14.3485 17.5375 14.2019 17.3906L13.9805 17.1687C13.8339 17.0219 13.8339 16.7844 13.9805 16.6375L18.0943 12.5312H9.36452C9.15867 12.5312 8.99025 12.3625 8.99025 12.1562V11.8438C8.99025 11.6375 9.15867 11.4688 9.36452 11.4688H18.0943L13.9774 7.3625C13.8308 7.21563 13.8308 6.97812 13.9774 6.83125Z", fill: "white" })),
                    React.createElement("g", { mask: "url(#mask0)" },
                        React.createElement("rect", { width: "24", height: "24", fill: "#14273C" }))),
                logoutControl.text))) : null)));
};
export default GatoHeader;
