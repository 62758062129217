"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var LoaderContext = React.createContext(undefined);
function LoadingProvider(props) {
    var _a = React.useState(false), loading = _a[0], setLoading = _a[1];
    var value = React.useMemo(function () { return ({ loading: loading, setLoading: setLoading }); }, [loading]);
    return React.createElement(LoaderContext.Provider, __assign({ value: value }, props));
}
exports.LoadingProvider = LoadingProvider;
function useLoader() {
    var context = React.useContext(LoaderContext);
    if (!context) {
        throw new Error("useLoading must be used within a LoadingProvider");
    }
    var loading = context.loading, setLoading = context.setLoading;
    var startLoading = React.useCallback(function () { return setLoading(function (_) { return true; }); }, [
        setLoading,
    ]);
    var stopLoading = React.useCallback(function () { return setLoading(function (_) { return false; }); }, [
        setLoading,
    ]);
    return {
        loading: loading,
        startLoading: startLoading,
        stopLoading: stopLoading
    };
}
exports["default"] = useLoader;
