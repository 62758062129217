import React from 'react';
import { makeStyles } from '@material-ui/styles';
const useStyles = makeStyles({
    errorContainer: {
        width: '100%',
        fontSize: 14,
        color: 'var(--accent)',
        textAlign: 'left',
        borderLeft: '4px solid var(--accent)',
        marginTop: '10px',
        paddingLeft: '10px',
        '& > p': {
            margin: 0,
            padding: 0,
        },
    },
});
const ErrorMessage = (props) => {
    const { condition, message } = props;
    const classes = useStyles();
    return condition ? (React.createElement("div", { className: `${classes.errorContainer} ${props.className}`, style: { ...props.style } },
        React.createElement("p", null, message || 'Esta pregunta es obligatoria.'))) : null;
};
export default ErrorMessage;
