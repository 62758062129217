export const GoogleLoginConfig = (element, onSuccess, onFailure, extraParams) => {
    const redirect_uri = `${window.location.origin}/google-redirect.html`;
    const config = {
        client_id: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID,
        cookie_policy: 'single_host_origin',
        scope: 'profile email',
        ux_mode: 'redirect',
        redirect_uri: encodeURI(redirect_uri),
    };
    localStorage.setItem('google-state', JSON.stringify(extraParams));
    try {
        // eslint-disable-next-line no-undef
        gapi.load('auth2', () => {
            // eslint-disable-next-line no-undef
            gapi.auth2
                .init(config)
                .attachClickHandler(element, {}, onSuccess, onFailure);
        });
    }
    catch (e) {
        console.log(e);
    }
};
export const FacebookLogin = (callback, queryParams) => {
    const redirect_uri = `${window.location.origin}/facebook-redirect.html`;
    // eslint-disable-next-line no-undef
    FB.getLoginStatus(response => {
        if (response.status === 'connected') {
            callback(response);
        }
        else {
            const href = encodeURI(`https://www.facebook.com/dialog/oauth?client_id=${process.env.REACT_APP_FB_APP_ID}&response_type=token&redirect_uri=${encodeURI(redirect_uri)}&state=${btoa(JSON.stringify(queryParams))}&scope=public_profile,email`);
            window.location.href = href;
        }
    });
};
export class NeedsRerequestFacebookError extends Error {
    constructor(message) {
        super(message);
        this.name = 'NeedsRerequestFacebookError';
    }
}
export default {
    GoogleLoginConfig,
    FacebookLogin,
    NeedsRerequestFacebookError,
};
