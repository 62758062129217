import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Fab, Grid } from '@material-ui/core';
import { shareUtils } from '@elgatoylacaja/utils';
import useAppStyles from './useAppStyles';
import FacebookIcon from './FacebookIcon';
import TwitterIcon from './TwitterIcon';
import WhatsappIcon from './WhatsappIcon';
import ClipboardIcon from './ClipboardIcon';
import { useTimeout } from '../../hooks/lib';
const { shareExperiment } = shareUtils;
const useStyles = makeStyles({
    button: {
        width: '45%',
        margin: '4px',
        fontSize: '16px',
        minHeight: '42px',
        fontWeight: 400,
        textTransform: 'none',
        borderRadius: '3px',
        padding: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconButton: {
        backgroundColor: 'white',
        border: '1px solid',
        boxShadow: '0 0 4px 0 rgba(20, 39, 60, 0.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            transform: 'scale(0.8)',
        },
    },
    twitter: {
        borderColor: 'var(--twitter)',
        '&:hover': {
            backgroundColor: 'var(--twitter)',
            '& path': {
                fill: 'white',
            },
        },
    },
    facebook: {
        borderColor: '#3D5A96',
        '&:hover': {
            backgroundColor: '#3D5A96',
            '& path': {
                fill: 'white',
            },
        },
    },
    whatsapp: {
        borderColor: 'var(--whatsapp)',
        '&:hover': {
            backgroundColor: 'var(--whatsapp)',
            '& path': {
                fill: 'white',
            },
        },
    },
    success: {
        cursor: 'default !important',
        '&:hover': {
            textDecoration: 'none !important',
        },
    },
});
function CheckIcon(props) {
    return (React.createElement("svg", Object.assign({ width: 14, height: 11, viewBox: "0 0 14 11", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: { marginRight: '8px' } }, props),
        React.createElement("path", { d: "M1 6.2l3.6 3.3L13 .8", stroke: "#69D38C", strokeWidth: 1.2, strokeLinecap: "round", strokeLinejoin: "round" })));
}
const ShareButtons = (props) => {
    const classes = useStyles();
    const appStyles = useAppStyles();
    const share = React.useCallback((service) => {
        return shareExperiment(props.EXPERIMENT_SHARE_TEXT, props.EXPERIMENT_URL)(service);
    }, [props.EXPERIMENT_SHARE_TEXT, props.EXPERIMENT_URL]);
    const [showCopySuccess, setShowCopySuccess] = React.useState(false);
    const [start] = useTimeout(5, () => {
        setShowCopySuccess(false);
    });
    return (React.createElement("div", { style: {
            border: 'solid 1px rgba(15, 39, 62, 0.1)',
            borderRadius: '6px',
            padding: '24px 40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '40px auto 0 auto',
            maxWidth: 'min(100%, 400px)',
        } },
        props.shareImage && (React.createElement(Grid, { container: true, justify: "center", style: {
                marginBottom: '32px',
                width: 'min(100%, 300px)',
                paddingTop: '100%',
                position: 'relative',
            } },
            React.createElement("img", { style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    maxWidth: 'min(100%, 300px)',
                }, src: props.shareImage, alt: "opengraph experimento" }))),
        React.createElement(Typography, { className: appStyles.text, style: {
                textAlign: 'center',
                fontSize: '16px',
                textTransform: 'uppercase',
            } }, props.customText?.share || (React.createElement(React.Fragment, null,
            React.createElement("b", null, "Compart\u00ED"),
            " el experimento:"))),
        React.createElement(Grid, { container: true, justify: "space-around", style: { marginTop: '32px' } },
            React.createElement(React.Fragment, null,
                React.createElement(Fab, { color: "primary", className: classes.iconButton + ' ' + classes.facebook, onClick: share('facebook') },
                    React.createElement(FacebookIcon, null)),
                React.createElement(Fab, { color: "primary", className: classes.iconButton + ' ' + classes.twitter, onClick: share('twitter') },
                    React.createElement(TwitterIcon, null)),
                React.createElement(Fab, { color: "primary", className: classes.iconButton + ' ' + classes.whatsapp, onClick: share('whatsapp') },
                    React.createElement(WhatsappIcon, null)))),
        React.createElement(Grid, { container: true, justify: "center", style: { marginTop: '24px' } }, showCopySuccess ? (React.createElement("span", { className: `${appStyles.link} ${classes.success}`, style: {
                color: '#69D38C',
                width: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            } },
            React.createElement(CheckIcon, null),
            "Copiado")) : (React.createElement("span", { className: appStyles.link, style: {
                width: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
            }, onClick: () => {
                const url = `${window.location.href}?share=clipboard`;
                navigator.clipboard.writeText(url).then(() => {
                    start();
                    setShowCopySuccess(true);
                }, err => {
                    console.error('Async: Could not copy text: ', err);
                });
            } },
            React.createElement(ClipboardIcon, null),
            "copiar link")))));
};
export default ShareButtons;
