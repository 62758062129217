import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { analyticsUtils } from '@elgatoylacaja/utils';
const useStyles = makeStyles({
    root: {
        marginTop: '24px',
        width: '95%',
        marginBottom: '10px',
    },
    expTitle: {
        fontFamily: 'Gotham',
        fontWeight: 700,
        textAlign: 'left',
        letterSpacing: '-0.56px',
        fontSize: 24,
        color: 'var(--secondary)',
        margin: '10px 0 0',
        '@media only screen and (max-width : 800px)': {
            fontSize: 18,
        },
    },
    expBody: {
        fontFamily: 'SourceSerifPro',
        textAlign: 'left',
        fontSize: 17,
        color: 'var(--secondary)',
        margin: '5px 0 0',
        '@media only screen and (max-width : 800px)': {
            fontSize: 14,
        },
    },
    expPreview: {
        width: '100%',
    },
    link: {
        textDecoration: 'none',
    },
});
const Recomendation = ({ title, description, imgUrl, experimentUrl, analyticsSlug, }) => {
    const classes = useStyles();
    return (React.createElement(Grid, { className: classes.root },
        React.createElement("a", { href: experimentUrl, rel: "noopener noreferrer", target: "_blank", onClick: () => {
                if (analyticsSlug !== undefined) {
                    analyticsUtils.CLICK_RECOMENDACION(analyticsSlug);
                }
            }, className: classes.link },
            React.createElement(Grid, null,
                React.createElement("img", { className: classes.expPreview, src: imgUrl, alt: "imagen experimento" })),
            React.createElement(Grid, null,
                React.createElement(Typography, { className: classes.expTitle }, title)),
            React.createElement(Grid, null,
                React.createElement(Typography, { className: classes.expBody }, description)))));
};
export default Recomendation;
