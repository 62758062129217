import { handleGet, handlePost, handlePut } from './api';
class LabsAPI {
    configure(api_url, experiment_slug) {
        this.LABS_API_URL = api_url;
        this.EXPERIMENT_SLUG = experiment_slug;
        this.GET = handleGet(this.LABS_API_URL);
        this.POST = handlePost(this.LABS_API_URL);
        this.PUT = handlePut(this.LABS_API_URL);
    }
    async submitAnswers(data, token) {
        return await this.POST('/api/auth/labs/experiment/store')(data, { 'X-AUTH-TOKEN': token, Authorization: `Bearer ${token}` });
    }
    async submitExtraData({ id, token, data }) {
        return await this.PUT(`/api/auth/labs/experiment/store/${id}`)(data, { 'X-AUTH-TOKEN': token, Authorization: `Bearer ${token}` });
    }
    async checkDone(experiment_slug, token) {
        return await this.POST(`/api/auth/labs/experiment/check`)({ experiment_slug }, { 'X-AUTH-TOKEN': token, Authorization: `Bearer ${token}` }).then(r => r.data);
    }
    async experimentData(experiment_slug) {
        return await this.GET(`/api/labs/experiment/${experiment_slug}`);
    }
    async checkThisIsDone(token) {
        return await this.checkDone(this.EXPERIMENT_SLUG, token);
    }
    async checkExperiments(token) {
        return await this.GET('/api/auth/labs/experiment/all', token);
    }
    async getPrevValues({ qty, key }) {
        return await this.POST(`/api/labs/experiment/prev_values`)({
            qty,
            key,
            experiment_slug: this.EXPERIMENT_SLUG,
        });
    }
    async getResult(experiment_slug, token) {
        return await this.POST('/api/auth/labs/experiment/result', token)({
            'experiment-slug': experiment_slug,
        }).then(res => res.data[0]);
    }
}
export default new LabsAPI();
