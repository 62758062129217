"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
var FloatingText = React.createContext(undefined);
function FloatingTextProvider(props) {
    var _a = React.useState(false), shouldShowFloatingText = _a[0], setShouldShowFloatingText = _a[1];
    var _b = React.useState([]), pathnames = _b[0], setPathnames = _b[1];
    var value = React.useMemo(function () { return ({
        shouldShowFloatingText: shouldShowFloatingText,
        setShouldShowFloatingText: setShouldShowFloatingText,
        pathnames: pathnames,
        setPathnames: setPathnames
    }); }, [shouldShowFloatingText, pathnames]);
    return React.createElement(FloatingText.Provider, __assign({ value: value }, props));
}
exports.FloatingTextProvider = FloatingTextProvider;
function useFLoatingText(pathnames, location) {
    var context = React.useContext(FloatingText);
    if (!context) {
        throw new Error("useFLoatingText must be used within a FloatingTextProvider");
    }
    var shouldShowFloatingText = context.shouldShowFloatingText, setShouldShowFloatingText = context.setShouldShowFloatingText, contextPathnames = context.pathnames, setPathnames = context.setPathnames;
    React.useEffect(function () {
        setPathnames(function () { return pathnames; });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var showFloatingText = React.useCallback(function () { return setShouldShowFloatingText(function (_) { return true; }); }, [setShouldShowFloatingText]);
    var hideFloatingText = React.useCallback(function () { return setShouldShowFloatingText(function (_) { return false; }); }, [setShouldShowFloatingText]);
    React.useEffect(function () {
        if (contextPathnames.find(function (path) { return path.includes(location); }) !== undefined) {
            showFloatingText();
        }
        else {
            hideFloatingText();
        }
    }, [contextPathnames, location, hideFloatingText, showFloatingText]);
    return {
        shouldShowFloatingText: shouldShowFloatingText
    };
}
exports["default"] = useFLoatingText;
