import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: {
          /* Login */
          loginHello: '¡Hola!',
          labsLoginTitle: 'Sumate a hacer ciencia colectiva',
          loginTitle: 'Más ciencia en más lugares',

          disclaimerWhy: '¿Por qué tengo que iniciar sesión?',
          disclaimerText:
            'Es necesario iniciar sesión para evitar que repitas un experimento y tomemos una muestra dos veces. Todos los datos están anonimizados y encriptados, y serán usados exclusivamente para investigación.',

          loginFacebook: 'Ingresar con <1>Facebook</1>',
          loginGoogle: 'Ingresar con <1>Google</1>',

          loginEmailInput: 'Email',
          loginPasswordInput: 'Contraseña',
          loginForgotPasswordLink: 'Olvidé mi contrseña',

          loginButton: 'Ingresar',
          loginSignupQuestion: '¿Todavía no tenés cuenta?',
          loginSignupLink: 'Crear cuenta',

          /* Signup */
          signupTitle: 'Creá tu cuenta',
          signupNameInput: 'Nombre',
          signupEmailInput: 'Email',
          signupPasswordInput: 'Contraseña',
          signupRepeatPasswordInput: 'Repetir contraseña',

          signupButton: 'Crear cuenta',

          signupLoginQuestion: '¿Ya tenés cuenta?',
          signupLoginLink: 'Ingresar',

          /* Recover */
          recoverTitle: 'Recuperar contraseña',
          recoverText:
            'Ingresá tu mail y te contactamos para cambiar tu contraseña.',

          recoverEmailInput: 'Email',

          recoverButton: 'Recuperar',

          recoverSignupQuestion: '¿Todavía no tenés cuenta?',
          recoverSignupLink: 'Crear cuenta',

          recoverMailSent: '¡Te enviamos un email!',
          recoverMailSentText:
            'Revisá tu casilla de correo y hace click en el enlace que te mandamos para crear una nueva contraseña.',

          /* New Password */
          newPasswordTitle: 'Crear nueva contraseña',
          newPasswordPasswordInput: 'Nueva contraseña',
          newPasswordConfirmPasswordInput: 'Repetir nueva contraseña',
          newPasswordButton: 'Crear contraseña',

          /* Error Messages */
          errorFacebookLogin:
            'Hubo un error al ingresar con facebook, al reintentar revisa los permisos necesarios',
          errorGoogleLogin: 'Hubo un error al ingresar con google',

          errorMailUsed: 'Este mail ya está en uso',
          errorInformationRequired: 'Este campo es requerido',
          errorUserNotFound: 'No existe un usuario con ese email',
          errorInvalidEmail: 'No es un email válido',
          errorPasswordDontMatch: 'Las contraseñas no coinciden',
          errorWrongPassword: 'Contraseña incorrecta',
        },
      },
      // en: {
      //   translation: {
      //     /* Login */
      //     loginHello: '¡Hola!',
      //     loginTitle: 'Be part of a TEDxperiment',

      //     disclaimerWhy: 'Why do I have to log in?',
      //     disclaimerText:
      //       "You need to log in so we can make sure you won't repeat the experiment and that we don't take the same sample twice. All data is anonymized, used exclusively for scientific research and will be published openly (again, anonymized) and made available to the scientific community together with the corresponding publications.",

      //     loginFacebook: 'Log in with <1>Facebook</1>',
      //     loginGoogle: 'Log in with <1>Google</1>',

      //     loginEmailInput: 'Email',
      //     loginPasswordInput: 'Password',
      //     loginForgotPasswordLink: 'I forgot my password',

      //     loginButton: 'Log in',
      //     loginSignupQuestion: "You don't have an account yet?",
      //     loginSignupLink: 'Create account',

      //     /* Signup */
      //     signupTitle: 'Create account',
      //     signupNameInput: 'Name',
      //     signupEmailInput: 'Email',
      //     signupPasswordInput: 'Password',
      //     signupRepeatPasswordInput: 'Repeat password',

      //     signupButton: 'Create account',

      //     signupLoginQuestion: 'Already have an account?',
      //     signupLoginLink: 'Log in',

      //     /* Recover */
      //     recoverTitle: 'Account recovery',
      //     recoverText:
      //       'Enter your email address below and we will send you a link where you can enter a new password.',

      //     recoverEmailInput: 'Email',

      //     recoverButton: 'Restore',

      //     recoverSignupQuestion: "You don't have an account yet?",
      //     recoverSignupLink: 'Create account',

      //     recoverMailSent: 'We sent you an email',
      //     recoverMailSentText:
      //       'Check your email and click on the link we sent you to enter your new password.',

      //     /* New Password */
      //     newPasswordTitle: 'Create new password',
      //     newPasswordPasswordInput: 'New password',
      //     newPasswordConfirmPasswordInput: 'Repeat new password',
      //     newPasswordButton: 'Create password',

      //     /* Error Messages */
      //     errorFacebookLogin:
      //       'There was an error logging in with facebook, try again and check the permissions.',
      //     errorGoogleLogin:
      //       'There was an error logging in with google. Try again later, or try with another account.',

      //     errorMailUsed: 'This mail is already in use.',
      //     errorInformationRequired: 'This information is required',
      //     errorUserNotFound:
      //       "The email you entered doesn't belong to an account. Please check your email and try again.",
      //     errorInvalidEmail:
      //       'The email you entered is not valid. Please check your email and try again.',
      //     errorPasswordDontMatch:
      //       "Your passwords don't match. Please double-check your passwords",
      //     errorWrongPassword:
      //       'Your password is incorrect. Please double-check your password.',
      //   },
      // },
    },
    lng: 'es',
    fallbackLng: 'es',
    // debug: true,
  })

export default i18n
