"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
exports.__esModule = true;
var React = __importStar(require("react"));
function useRange(initial, min, max) {
    var _a = React.useState(initial), value = _a[0], set = _a[1];
    var increment = React.useCallback(function () { return set(function (value) { return Math.min(max, value + 1); }); }, [max]);
    var decrement = React.useCallback(function () { return set(function (value) { return Math.max(min, value - 1); }); }, [min]);
    var setValue = React.useCallback(function (newValue) {
        newValue > max ? set(max) : newValue < min ? set(min) : set(newValue);
    }, [max, min, set]);
    return {
        value: value,
        increment: increment,
        decrement: decrement,
        setValue: setValue
    };
}
exports["default"] = useRange;
