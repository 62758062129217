import React from 'react';
const ChevronIconUp = (props) => {
    const id = Math.floor((Math.random() * 100) % 100);
    return (React.createElement("svg", Object.assign({ width: 24, height: 24, viewBox: "0 0 24 24" }, props),
        React.createElement("mask", { id: `prefix__icon_up_${id}`, maskUnits: "userSpaceOnUse", x: 5, y: 7, width: 14, height: 9 },
            React.createElement("path", { d: "M5.19 14.772l.62.619a.375.375 0 00.53 0L12 9.744l5.66 5.647a.375.375 0 00.53 0l.62-.62a.375.375 0 000-.53l-6.544-6.544a.375.375 0 00-.532 0L5.191 14.24a.375.375 0 000 .53z", fill: "#fff" })),
        React.createElement("g", { mask: `url(#prefix__icon_up_${id})` },
            React.createElement("path", { fill: props.color, d: "M24 24H0V0h24z" }))));
};
export default ChevronIconUp;
