import ReactGA from 'react-ga';
const capitalizeFirst = (x) => `${x.charAt(0).toUpperCase()}${x.slice(1, x.length)}`;
export default {
    INITIALIZE: (api_key) => {
        ReactGA.initialize(api_key);
    },
    REGISTER_VISIT: (location) => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Visited',
            label: location,
        });
    },
    REGISTER_LEFT: (location) => {
        ReactGA.event({
            category: 'Navigation',
            action: 'Left',
            label: location,
        });
    },
    SUCCESS_SERVICE: (service) => ReactGA.event({
        category: 'Login Service',
        action: `Success ${capitalizeFirst(service)}`,
    }),
    ERROR_SERVICE: (service, extra = '') => ReactGA.event({
        category: 'Login Service',
        action: `Error ${capitalizeFirst(service)} ${extra}`,
    }),
    CLICK_SERVICE: (service) => ReactGA.event({
        category: 'Login Service',
        action: `Click ${service}`,
    }),
    CLICK_SHARE: (service) => ReactGA.event({
        category: 'Share',
        action: `Click ${service}`,
    }),
    VISIT_BY_SHARE: (service) => ReactGA.event({
        category: 'Share',
        action: `Visit by ${service}`,
    }),
    EXPERIMENT_COMPLETE: () => ReactGA.event({
        category: 'Experiment',
        action: `Complete`,
    }),
    CLICK_RECOMENDACION: (recomendado) => ReactGA.event({
        category: 'Click Recomendado',
        action: `Click ${recomendado}`,
    }),
    EVENT: (args) => ReactGA.event(args),
};
