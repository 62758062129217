import * as React from 'react';
const GatoLogo = React.createContext(undefined);
export function GatoLogoProvider(props) {
    const [shouldShowGatoLogo, setShouldShowGatoLogo] = React.useState(false);
    const [pathnames, setPathnames] = React.useState([]);
    const value = React.useMemo(() => ({
        shouldShowGatoLogo,
        setShouldShowGatoLogo,
        pathnames,
        setPathnames,
    }), [shouldShowGatoLogo, pathnames]);
    return React.createElement(GatoLogo.Provider, Object.assign({ value: value }, props));
}
export default function useGatoLogo(pathnames, location) {
    const context = React.useContext(GatoLogo);
    if (!context) {
        throw new Error(`useGatoLogo must be used within a GatoLogoProvider`);
    }
    const { shouldShowGatoLogo, setShouldShowGatoLogo, pathnames: contextPathnames, setPathnames, } = context;
    React.useEffect(() => {
        setPathnames(() => pathnames);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const showGatoLogo = React.useCallback(() => setShouldShowGatoLogo(_ => true), [setShouldShowGatoLogo]);
    const hideGatoLogo = React.useCallback(() => setShouldShowGatoLogo(_ => false), [setShouldShowGatoLogo]);
    React.useEffect(() => {
        if (contextPathnames.find(path => path.includes(location)) !== undefined) {
            showGatoLogo();
        }
        else {
            hideGatoLogo();
        }
    }, [contextPathnames, location, hideGatoLogo, showGatoLogo]);
    return {
        shouldShowGatoLogo,
    };
}
