function isValidRedirect(href: string | null): href is string {
  try {
    const url = new URL(href === null ? '' : href)
    return (
      url.hostname.includes('elgatoylacaja') ||
      process.env.NODE_ENV !== 'production'
    )
  } catch (e) {
    return false
  }
}

export function loginRedirect(
  accessToken: string,
  redirectUrl: string | null = sessionStorage.getItem('redirectUrl')
) {
  return new Promise(resolve => {
    const url = new URL(
      isValidRedirect(redirectUrl)
        ? redirectUrl
        : 'https://elgatoylacaja.com/perfil'
    )
    url.searchParams.set('accessToken', accessToken)
    window.location.replace(url.href)
  })
}
