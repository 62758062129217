import React from 'react';
import { Tooltip, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import InfoIcon from './InfoIcon';
function arrowGenerator(color) {
    return {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.95em',
            width: '2em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '2em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.95em',
            height: '2em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.95em',
            height: '2em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`,
            },
        },
    };
}
const useStyles = ({ maxWidth = '200px', backgroundColor = 'var(--secondary)', }) => makeStyles({
    buttonStyle: {
        fontSize: 18,
    },
    arrow: {
        position: 'absolute',
        fontSize: 6,
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    popper: arrowGenerator(backgroundColor),
    tooltip: {
        position: 'relative',
        backgroundColor: backgroundColor,
        fontFamily: 'Gotham',
        fontWeight: 400,
        letterSpacing: '-0.56px',
        fontSize: 16,
        '@media only screen and (max-width : 758px)': {
            fontSize: 14,
        },
        maxWidth: maxWidth,
    },
    tooltipPlacementLeft: {
        margin: '0 8px',
    },
    tooltipPlacementRight: {
        margin: '0 8px',
    },
    tooltipPlacementTop: {
        margin: '8px 0',
    },
    tooltipPlacementBottom: {
        margin: '8px 0',
    },
});
function TooltipLabel(props) {
    const { arrow, buttonStyle, ...classes } = useStyles({
        maxWidth: props.maxWidth,
    })();
    const [arrowRef, setArrowRef] = React.useState(null);
    const { color = 'var(--primary)', hoverColor = '#68bbe866', Icon, onOpen = () => { }, onClose = () => { }, } = props;
    const [iconColor, setIconColor] = React.useState(color);
    return (React.createElement("div", { style: {
            display: 'inline-flex',
            justifyContent: 'center',
            alignItems: 'center',
        } },
        React.createElement(Tooltip, { disableFocusListener: true, disableTouchListener: true, classes: classes, PopperProps: {
                popperOptions: {
                    modifiers: {
                        arrow: {
                            enabled: Boolean(arrowRef),
                            element: arrowRef,
                        },
                    },
                },
            }, style: {
                marginLeft: '10px',
                width: '25px',
            }, title: React.createElement(React.Fragment, null,
                props.text,
                React.createElement("span", { className: arrow, ref: setArrowRef })), placement: props.placement, onOpen: () => {
                onOpen();
                setIconColor(hoverColor);
            }, onClose: () => {
                onClose();
                setIconColor(color);
            } },
            React.createElement(ButtonBase, { disableRipple: true, disableTouchRipple: true, className: buttonStyle }, Icon ? React.createElement(Icon, { color: iconColor }) : React.createElement(InfoIcon, { color: iconColor })))));
}
export default TooltipLabel;
