"use strict";
exports.__esModule = true;
var react_1 = require("react");
var x = function (userAgent) {
    var isAndroid = function () { return Boolean(userAgent.match(/Android/i)); };
    var isIos = function () { return Boolean(userAgent.match(/iPhone|iPad|iPod/i)); };
    var isOpera = function () { return Boolean(userAgent.match(/Opera Mini/i)); };
    var isWindows = function () { return Boolean(userAgent.match(/IEMobile/i)); };
    var isMobile = function () {
        return Boolean(isAndroid() || isIos() || isOpera() || isWindows());
    };
    return {
        isMobile: isMobile,
        isAndroid: isAndroid,
        isIos: isIos
    };
};
function useIsMobile() {
    var _a = react_1.useState(false), isMobile = _a[0], setIsMobile = _a[1];
    react_1.useEffect(function () {
        setIsMobile(x(navigator.userAgent).isMobile());
    }, []);
    return { isMobile: isMobile };
}
exports["default"] = useIsMobile;
