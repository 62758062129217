import React from 'react'
import ReactDOM from 'react-dom'
import App from 'App'
import * as serviceWorker from 'serviceWorker'
import { gatoAPI, analyticsUtils } from '@elgatoylacaja/utils'

import 'i18n.tsx'

if (process.env.NODE_ENV === 'production') {
  analyticsUtils.INITIALIZE(process.env.REACT_APP_GOOGLE_GTAG_ID as string)
}

gatoAPI.configure(process.env.REACT_APP_GATO_API_URL as string)

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.register()
