import React from 'react';
import { MenuItem, FormControl, FormLabel, Paper, makeStyles, Divider, Typography, } from '@material-ui/core';
import useAppStyles from './useAppStyles';
import TextField from './TextField';
import CustomErrorMessage from './CustomFormikErrorMessage';
import { useCombobox } from 'downshift';
import { matchSorter } from 'match-sorter';
import { ErrorMessage } from 'formik';
const useStyles = makeStyles({
    input: {
        '@media only screen and (max-width : 800px)': {
            fontSize: 15,
        },
        fontSize: 18,
        fontFamily: 'Gotham',
        fontWeight: 400,
        color: 'var(--secondary)',
        textAlign: 'left',
        height: '30px',
        display: 'flex',
        alignItems: 'center',
        '&::placeholder': {
            color: 'rgb(162, 162, 162)',
            opacity: '1',
        },
    },
    cssUnderline: {
        '&:after': {
            borderBottomColor: 'var(--primary)',
        },
    },
});
const itemToString = (item) => (item ? item.label : '');
const Suggestion = (suggestionProps) => {
    const { suggestion, isHighlighted, isSelected, itemProps, custom = false, } = suggestionProps;
    const appStyles = useAppStyles();
    return (React.createElement(MenuItem, Object.assign({}, itemProps, { selected: isHighlighted, component: "div", style: {
            fontWeight: isSelected ? 500 : 400,
            fontFamily: 'Gotham',
            height: '48px',
        } }),
        custom ? (React.createElement(Typography, { variant: "caption", className: appStyles.textLight, style: {
                display: 'inline-flex',
                marginRight: '2px',
                width: 'fit-content',
            } }, "Agregar:")) : null,
        ' ',
        suggestion.label));
};
const OtherOptionsItem = () => (React.createElement(MenuItem, { disabled: true, component: "div", style: {
        fontWeight: 500,
        fontFamily: 'Gotham',
        fontSize: '14px',
        color: 'gray',
        textTransform: 'uppercase',
        marginTop: '16px',
        height: '48px',
    } }, "Otras opciones"));
const lowerJoin = (text) => text
    .toLowerCase()
    .split(' ')
    .join('');
const SuggestionTextField = (props) => {
    const appStyles = useAppStyles();
    const classes = useStyles();
    const { values, initialValue, allowOthers = false, onValueChange, filterOther = () => false, filterFirst = () => true, } = props;
    const [inputItems, setInputItems] = React.useState(values);
    const optionFilterFunc = React.useCallback((items, inputValue) => {
        return matchSorter(items.filter(filterFirst), inputValue, {
            keys: [
                { maxRanking: matchSorter.rankings.STARTS_WITH, key: 'label' },
                'extra',
            ],
        })
            .slice(0, props.limit)
            .concat(items.filter(filterOther));
    }, [props.limit]);
    const findSimilar = React.useCallback(value => {
        return values.find(({ label, extra = '' }) => lowerJoin(label) === lowerJoin(value) ||
            (extra !== '' && lowerJoin(extra) === lowerJoin(value)));
    }, [values]);
    const { isOpen, getLabelProps, getMenuProps, getInputProps, highlightedIndex, getComboboxProps, selectItem, getItemProps, selectedItem, setHighlightedIndex, setInputValue, openMenu, closeMenu, } = useCombobox({
        items: inputItems,
        initialSelectedItem: values.find(option => option.id.toString() === initialValue),
        onSelectedItemChange: ({ selectedItem }) => {
            onValueChange(selectedItem);
        },
        onInputValueChange: ({ inputValue }) => {
            const filteredItems = optionFilterFunc(values, inputValue || '');
            if (filteredItems.length < 4 && allowOthers && inputValue !== '') {
                setInputItems([
                    ...filteredItems,
                    { label: `${inputValue}`, value: 'custom', id: 999 },
                ]);
            }
            else {
                setInputItems(filteredItems);
            }
        },
        onIsOpenChange: changes => {
            const { isOpen, inputValue } = changes;
            if (!isOpen) {
                const similarItem = findSimilar(inputValue);
                if (similarItem) {
                    selectItem(similarItem);
                    setInputItems(values);
                    // } else if (allowOthers) {
                    //   const newItem = { label: `${inputValue}`, value: 'custom', id: 999 }
                    //   selectItem(newItem)
                }
                else {
                    setInputValue('');
                }
            }
        },
        onStateChange: changes => {
            const { type, selectedItem, inputValue } = changes;
            switch (type) {
                case useCombobox.stateChangeTypes.InputChange:
                    const similarItem = values.find(({ label, extra = '' }) => lowerJoin(label).startsWith(lowerJoin(inputValue)) ||
                        lowerJoin(extra).startsWith(lowerJoin(inputValue)));
                    if (similarItem) {
                        setHighlightedIndex(0);
                    }
                    break;
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (selectedItem) {
                        const similarItem = findSimilar(selectedItem.label);
                        if (similarItem && inputValue !== '') {
                            selectItem(similarItem);
                        }
                        else if (allowOthers) {
                            selectItem(selectedItem);
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        itemToString,
    });
    const other = values.filter(filterOther);
    const { onBlur, onFocus, autoComplete, ...inputProps } = getInputProps({
        onFocus: () => {
            if (!isOpen) {
                openMenu();
            }
        },
    });
    const isSelected = React.useCallback((label) => itemToString(selectedItem) === label, [selectedItem]);
    return (React.createElement(FormControl, Object.assign({ fullWidth: true, className: appStyles.formControl }, getComboboxProps()),
        React.createElement(FormLabel, Object.assign({}, getLabelProps(), { className: appStyles.formLabel }), props.label),
        React.createElement(TextField, { InputProps: {
                onBlur,
                onFocus,
                classes: {
                    input: classes.input,
                    underline: classes.cssUnderline,
                },
            }, fullWidth: true, inputProps: inputProps, placeholder: props.placeholder, autoComplete: "off" }),
        React.createElement("div", Object.assign({}, getMenuProps()), isOpen ? (React.createElement(Paper, { square: true, style: {
                maxHeight: `${props.limit * 48 +
                    (other.length !== 0 ? 48 + 16 : 0) +
                    24}px`,
                overflowY: 'scroll',
            } },
            inputItems
                .filter(filterFirst)
                .map((suggestion, index) => (React.createElement(React.Fragment, { key: suggestion.id },
                suggestion.value === 'custom' ? React.createElement(Divider, null) : null,
                React.createElement(Suggestion, { suggestion: suggestion, custom: suggestion.value === 'custom', itemProps: getItemProps({
                        item: suggestion,
                        index,
                    }), isSelected: isSelected(suggestion.label), isHighlighted: highlightedIndex === index })))),
            other.length !== 0 ? (React.createElement(React.Fragment, null,
                React.createElement(OtherOptionsItem, null),
                other.map((suggestion, index) => (React.createElement(Suggestion, { suggestion: suggestion, key: suggestion.id, itemProps: getItemProps({
                        item: suggestion,
                        index: inputItems.findIndex(i => i.value === suggestion.value),
                    }), isSelected: isSelected(suggestion.label), isHighlighted: highlightedIndex === inputItems.length + index }))))) : null)) : null),
        React.createElement(ErrorMessage, { name: props.id, component: CustomErrorMessage })));
};
export default SuggestionTextField;
