import * as React from 'react';
const LoaderContext = React.createContext(undefined);
export function LoadingProvider(props) {
    const [loading, setLoading] = React.useState(false);
    const value = React.useMemo(() => ({ loading, setLoading }), [loading]);
    return React.createElement(LoaderContext.Provider, Object.assign({ value: value }, props));
}
export default function useLoader() {
    const context = React.useContext(LoaderContext);
    if (!context) {
        throw new Error(`useLoading must be used within a LoadingProvider`);
    }
    const { loading, setLoading } = context;
    const startLoading = React.useCallback(() => setLoading(_ => true), [
        setLoading,
    ]);
    const stopLoading = React.useCallback(() => setLoading(_ => false), [
        setLoading,
    ]);
    return {
        loading,
        startLoading,
        stopLoading,
    };
}
